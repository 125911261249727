export const formatAmount = (number) => {
  const num = Number(number);

  if (isNaN(num)) {
    console.warn(`formatAmount received invalid input: ${number}`);
    return "0";
  }

  if (Number.isInteger(num)) {
    return num.toString();
  } else {
    return parseFloat(num.toFixed(2)).toString();
  }
};
