import styles from "./DrumsModal.module.scss";

import React, {
  useState,
  useMemo,
  useContext,
  useCallback,
  useRef,
} from "react";

import ModalWrapper from "../../base/ModalWrapper/ModalWrapper";
import Text from "../../base/Text/Text";
import Button from "../../base/Button/Button";

import { useApp } from "../../../context/AppProvider";
import useApi from "../../../api/rest";

import DrumIcon from "./DrumList/DrumIcon";
import DrumList from "./DrumList/DrumList";
import { useErrorHandler } from "../../Error/useErrorHandler";

export const DrumsModal = React.memo(({ onClose }) => {
  const { userInfo, setUserInfo } = useApp();
  const { balance = 0, gameLevel = 1, displayDrums = [] } = userInfo ?? {};
  const { totalDrumsPerTap, displayDrum, allDrums, lastUpgradedDrumId } =
    useDrums();
  const { upgradeDrum } = useApi();

  const [message, setMessage] = useState({ show: false, text: "" });
  const isUpgrading = useRef(false);
  const upgradeTimeout = useRef(null);

  const { handleError } = useErrorHandler();

  const showMessageHandler = useCallback((text) => {
    setMessage({ show: true, text });
    setTimeout(() => setMessage({ show: false, text: "" }), 3000);
  }, []);

  const handleUpgradeDrum = useCallback(
    async (drumId) => {
      const drum = displayDrums.find((d) => d.id === drumId);

      if (!drum) {
        showMessageHandler("Drum not found");

        return;
      }

      if (gameLevel < drum.levelRequired) {
        showMessageHandler("Your level is too low to upgrade this drum");

        return;
      }

      if (drum.id !== lastUpgradedDrumId + 1) {
        showMessageHandler("Please, update the previous drum");

        return;
      }

      if (balance < drum.cost) {
        showMessageHandler("Insufficient balance to upgrade this drum");
        return;
      }

      try {
        if (isUpgrading.current) {
          return;
        }

        if (upgradeTimeout.current) {
          return;
        }

        isUpgrading.current = true;

        upgradeTimeout.current = setTimeout(() => {
          upgradeTimeout.current = null;
        }, 2000);

        const response = await upgradeDrum(drumId);

        if (response.status === "error") {
          handleError(
            response.error,
            {},
            {
              displayDrum,
              allDrums,
              lastUpgradedDrumId,
              userDrums: userInfo?.drums,
              userDrumId: userInfo?.drumId,
              balance,
              drumId,
              gameLevel,
            },
          );
          showMessageHandler("Failed to upgrade drum");
          return;
        }

        const {
          drums: updatedDrums,
          balance: newBalance,
          spentDrums,
          drumId: newDrumId,
        } = response;

        setUserInfo((prevUserInfo) => ({
          ...prevUserInfo,
          drums: updatedDrums,
          balance: newBalance,
          spentDrums,
          drumId: newDrumId,
        }));
      } catch (error) {
        console.error("Failed to upgrade drum:", error);
        showMessageHandler("An error occurred while upgrading the drum");
      } finally {
        isUpgrading.current = false;
      }
    },
    [
      balance,
      gameLevel,
      displayDrums,
      lastUpgradedDrumId,
      upgradeDrum,
      setUserInfo,
      showMessageHandler,
      handleError,
      displayDrum,
      allDrums,
      userInfo?.drums,
      userInfo?.drumId,
    ],
  );

  React.useEffect(() => {
    return () => {
      if (upgradeTimeout.current) {
        clearTimeout(upgradeTimeout.current);
      }
    };
  }, []);

  return (
    <ModalWrapper
      onClose={onClose}
      className={styles.drumsModal}
      header={
        <span className={styles.bonus}>
          <Text variant="textM" weight="bold">
            +{totalDrumsPerTap ?? 0} Drums Per Tap
          </Text>
        </span>
      }
    >
      <Text
        variant="h1"
        weight="bold"
        className={styles.sectionTitle}
        color="gradient"
      >
        Drums
      </Text>

      {/* Drum Info */}
      <div className={styles.drumInfo}>
        <Button variant="icon" size="size0" className={styles.iconWrapper}>
          <DrumIcon
            name={displayDrum?.name ?? ""}
            className={styles.drumIcon}
          />
          <div className={styles.backShine} />
        </Button>
        <Text variant="textXL" weight="bold" className={styles.currentDrumName}>
          {displayDrum?.name ?? "No drum selected"}
        </Text>
      </div>

      {/* Drum list */}
      <DrumList
        filteredDrums={allDrums}
        gameLevel={gameLevel}
        onUpgrade={handleUpgradeDrum}
        selectedDrumId={displayDrum?.id}
        totalDrumsPerTap={totalDrumsPerTap}
        lastUpgradedDrumId={lastUpgradedDrumId}
      />
      {/* Popup message */}
      {message.show && (
        <div className={styles.popupMessage}>
          <Text variant="textXM" weight="bold">
            {message.text}
          </Text>
        </div>
      )}
    </ModalWrapper>
  );
});

export const DrumsModalContext = React.createContext(null);

export const DrumsModalProvider = React.memo(({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { userInfo } = useApp();

  const {
    balance = "0",
    gameLevel = 1,
    drums = [],
    displayDrums = [],
    drumId = 0,
  } = userInfo ?? {};

  const { displayDrum, allDrums, lastUpgradedDrumId } = useMemo(() => {
    const lastUpgradedDrumId = [...drums].sort((a, b) => b - a)[0] ?? 0;

    const canUpgradeDrum = (drum) => {
      const isUpgraded = drums.includes(drum.id);

      return (
        parseFloat(balance) >= drum.cost &&
        gameLevel >= drum.levelRequired &&
        !isUpgraded &&
        drum.id === lastUpgradedDrumId + 1
      );
    };

    const processedDrums = displayDrums.map((drum) => ({
      ...drum,
      isActive: drum.id === drumId,
      canUpgrade: canUpgradeDrum(drum),
      isUpgraded: drums.includes(drum.id),
    }));

    return {
      displayDrum: processedDrums.find((drum) => drum.id === drumId) ?? null,
      allDrums: processedDrums.sort((a, b) => a.id - b.id),
      lastUpgradedDrumId,
    };
  }, [balance, gameLevel, drums, displayDrums, drumId]);

  const totalDrumsPerTap = useMemo(() => {
    if (!displayDrum) return "0";

    if (displayDrum.tapRewardMin === displayDrum.tapRewardMax) {
      return displayDrum.tapRewardMin.toString();
    }

    return `${displayDrum.tapRewardMin}-${displayDrum.tapRewardMax}`;
  }, [displayDrum]);

  const handleModalState = (val) => {
    setIsOpen(val);
    if (val) {
      if (typeof window !== "undefined") {
        window.scrollTo(0, 0);
      }
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  const contextValue = useMemo(
    () => ({
      isOpen,
      setIsOpen: handleModalState,
      totalDrumsPerTap,
      displayDrum,
      allDrums,
      lastUpgradedDrumId,
    }),
    [isOpen, totalDrumsPerTap, displayDrum, allDrums, lastUpgradedDrumId],
  );

  return (
    <DrumsModalContext.Provider value={contextValue}>
      {children}
      {isOpen && <DrumsModal onClose={() => handleModalState(false)} />}
    </DrumsModalContext.Provider>
  );
});

export const useDrums = () => useContext(DrumsModalContext);
