import styles from "./DailyBonusModal.module.scss";
import React, { useState, useMemo, useCallback } from "react";
import ModalWrapper from "../../base/ModalWrapper/ModalWrapper";
import Text from "../../base/Text/Text";
import Button from "../../base/Button/Button";
import { useApp } from "../../../context/AppProvider";
import Icon from "../../Icon/Icon";
import ShineTokenName from "../../base/ShineTokenName/ShineTokenName";
import DailyBonusList from "./DailyBonusList/DailyBonusList";
import useApi from "../../../api/rest";

export const DailyBonusModal = ({ onClose }) => {
  const { setUserInfo, userInfo } = useApp();
  const { claimDailyBonus } = useApi();

  const { dailyBonus = {} } = userInfo || {};
  const [isLoading, setIsLoading] = useState(false);

  const { lastClaimDate, streakCount = 0 } = dailyBonus;

  const { activeStreak } = useMemo(() => {
    if (!lastClaimDate) {
      return { isStreakBroken: false, activeStreak: 0 };
    }

    const lastClaim = new Date(lastClaimDate);
    const today = new Date();

    // Reset time parts to compare just dates
    lastClaim.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    // Calculate days difference
    const diffTime = Math.abs(today - lastClaim);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    // If more than 1 day has passed, streak is broken
    const isStreakBroken = diffDays > 1;

    // If streak is broken, show 0 until they claim again
    const activeStreak = isStreakBroken ? 0 : streakCount;

    return { isStreakBroken, activeStreak };
  }, [lastClaimDate, streakCount]);

  const completedDays = useMemo(() => activeStreak % 7, [activeStreak]);

  const claimableToday = useMemo(() => {
    if (!lastClaimDate) return true;

    const lastClaim = new Date(lastClaimDate);
    const today = new Date();

    // Reset time parts to compare just dates
    lastClaim.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    return lastClaim.getTime() !== today.getTime();
  }, [lastClaimDate]);

  const claimedToday = useMemo(() => {
    if (!lastClaimDate) return false;
    const lastClaim = new Date(lastClaimDate);
    const today = new Date();

    // Reset time parts to compare just dates
    lastClaim.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    return lastClaim.getTime() === today.getTime();
  }, [lastClaimDate]);

  const isLastDay = completedDays === 6;
  const bonusAmount = isLastDay ? 500 : 100;

  const handleClaim = useCallback(async () => {
    if (!claimableToday || isLoading) return;

    setIsLoading(true);
    try {
      const response = await claimDailyBonus();
      setUserInfo((prev) => ({
        ...prev,
        balance: response.balance,
        dailyBonus: response.dailyBonus,
      }));
    } catch (error) {
      console.error("Failed to claim bonus:", error);
    } finally {
      setIsLoading(false);
    }
  }, [claimableToday, isLoading, claimDailyBonus, setUserInfo]);

  return (
    <ModalWrapper
      onClose={onClose}
      className={styles.dailyModal}
      backgroundClassName={styles.backgroundWrapper}
      closeButtonClassName={styles.closeButton}
      header={
        <div className={styles.header}>
          <div className={styles.checkIcon}>
            <Icon name="check-circle" width="40px" height="40px" />
          </div>
          <Text variant="textL" weight="bold">
            Claim Your Daily Reward!
          </Text>
        </div>
      }
    >
      <div className={styles.content}>
        <div className={styles.dailyBonus}>
          <Text variant="h2" weight="bold">
            +{bonusAmount} <ShineTokenName />
          </Text>
        </div>
        <Text variant="textL" weight="semi-bold">
          Collect rewards for 6 consecutive days, and on the 7th day, receive a
          bonus reward of 500 DRUMs!
        </Text>
        <DailyBonusList
          completedDays={completedDays}
          claimableToday={claimableToday}
          claimedToday={claimedToday}
        />
        <Button
          variant="filledAccentV2"
          className={styles.mainButton}
          onClick={claimedToday ? onClose : handleClaim}
          disabled={!claimableToday && !claimedToday}
        >
          <Text variant="textXL" weight="bold">
            {claimedToday ? "Done" : "Claim Reward!"}
          </Text>
        </Button>
      </div>
    </ModalWrapper>
  );
};

export const DailyBonusModalContext = React.createContext(null);

export const DailyBonusModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleModalState = (val) => {
    setIsOpen(val);
    if (val && typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  };

  return (
    <DailyBonusModalContext.Provider
      value={{ isOpen, setIsOpen: handleModalState }}
    >
      {children}
      {isOpen && <DailyBonusModal onClose={() => handleModalState(false)} />}
    </DailyBonusModalContext.Provider>
  );
};
