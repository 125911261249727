import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import useApi from "../api/rest";
import Text from "./base/Text/Text";
import styles from "./ErrorBoundary.module.scss";
import { useErrorHandler } from "./Error/useErrorHandler";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div
      className={styles.container}
      style={{
        padding: "5px 25px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        "::before": {
          height: "250px !important",
          bottom: "0",
          background:
            "linear-gradient(10deg, #18261C 10px, rgba(24, 38, 28, 0) 100%); !important",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          marginBottom: "-20px",
        }}
      >
        <img src="/img/brokenDrum.png" width="260px" alt="" />
      </div>
      <Text variant="h2" align="left">
        Oops! Something went wrong.
      </Text>
      <Text variant="textXl" color="gradient" tag="div" align="left">
        We've identified an issue with the game.
        <br />A report regarding the crash has been submitted to the Wigwam team
        for investigation.
        <br />
        <br />
        Please try again later.
      </Text>
    </div>
  );
};

const ErrorBoundaryWrapper = ({ children }) => {
  const { sendLog } = useApi();
  const { handleError } = useErrorHandler(sendLog);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
