import React, { forwardRef } from "react";

const DrumIcon = forwardRef(
  ({ name, width, height, className, ...props }, ref) => {
    return (
      <img
        ref={ref}
        src={`/img/drums/${name}.avif`}
        alt={`Drum icon ${name}`}
        // width={width ? width : "null"}
        // height={height}
        className={className}
        {...props}
      />
    );
  },
);

export default DrumIcon;
