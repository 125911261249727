import React from "react";
import Icon from "../Icon/Icon";
import styles from "./Notification.module.scss";
import Text from "../base/Text/Text";

const Notification = ({ onReadMore, onClose, text }) => {
  const handleCloseClick = (e) => {
    e.stopPropagation();
    onClose();
  };

  if (!text) {
    return null;
  }

  return (
    <div className={styles.notification} onClick={onReadMore}>
      <div>
        <Icon
          name="telegram"
          width="32px"
          height="32px"
          className={styles.icon}
        />
      </div>
      <Text variant="textM" align="left" className={styles.textButton}>
        {text}
      </Text>
      <button
        type="button"
        onClick={handleCloseClick}
        className={styles.closeButton}
      >
        <Icon
          width="24px"
          height="24px"
          name="close"
          className={styles.closeIcon}
        />
      </button>
    </div>
  );
};

export default Notification;
