import styles from "../Tasks.module.scss";
import clsx from "clsx";
import Text from "../../../base/Text/Text";
import Icon from "../../../Icon/Icon";
import { formatNumberForUi } from "../../../../utils/strings";
import { TOKEN_NAME } from "../../../../constants";
import Button from "../../../base/Button/Button";
import Loader from "../../../Loader/Loader";
import VerifyEmail from "../../../Modals/VerifyEmail/VerifyEmail";
import { useState } from "react";
import TaskDescriptionModal from "../../../Modals/DisclaimerModal/TaskDescriptionModal";

const RegisterTask = ({
  userData,
  task,
  handleStartTask,
  startTaskLoading,
  handleGoTask,
  handleClaim,
  claimTaskLoading,
}) => {
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const handleVerifyModal = () => {
    setShowVerifyModal(true);
  };

  return (
    <>
      <li key={task.taskId} className={styles.task}>
        <div className={styles.descriptionContainer}>
          <div
            className={clsx(
              styles.description,
              task?.taskData?.state === "Claimed" ? styles.claimed : "",
            )}
          >
            <div className={styles.title}>
              <Text
                variant="textM"
                weight="semiBold"
                align="left"
                className={styles.content}
              >
                {task.taskData.title}{" "}
                {task.taskData?.taskDetails?.description
                  ? `(${task.taskData.taskDetails.description})`
                  : ""}
                {task.taskData.taskDetails?.tooltipDescription &&
                  task?.taskData?.state !== "Claimed" && (
                    <Button
                      className={styles.descButton}
                      variant="icon"
                      size="size0"
                      onClick={() => setShowDescriptionModal(true)}
                    >
                      <Icon name="info" width="20px" height="20px" />
                    </Button>
                  )}
              </Text>
            </div>
            <div className={styles.reward}>
              <Icon name="cexIconAccent" width="14px" height="14px" />
              <Text variant="textM" color="primary" align="left">
                {formatNumberForUi(task.taskData.reward)} {TOKEN_NAME}
              </Text>
            </div>
          </div>
          {task?.taskData?.state === "NONE" && (
            <Button
              variant="filled"
              size="sizeM"
              onClick={() => handleStartTask(task)}
              disabled={startTaskLoading === task.taskId}
            >
              {startTaskLoading === task.taskId && <Loader disabled />}
              <Text variant="textM" weight="semiBold">
                Start
              </Text>
            </Button>
          )}
          {task?.taskData?.state === "Claimed" && (
            <Icon name="success" width="24px" height="24px" />
          )}
        </div>
        {task?.taskData?.state !== "NONE" &&
          task?.taskData?.state !== "Claimed" && (
            <div className={styles.actionsContainer}>
              <Button
                variant="outlined"
                size="sizeM"
                fullWidth
                onClick={() => handleGoTask(task.taskData)}
              >
                <Text variant="textM" weight="semiBold" color="secondary">
                  Go
                </Text>
              </Button>
              {task?.taskData?.state === "ReadyToCheck" ? (
                <Button
                  className={styles.checkBtn}
                  variant="filled"
                  size="sizeM"
                  fullWidth
                  onClick={handleVerifyModal}
                >
                  <Text variant="textM" weight="semiBold">
                    Check
                  </Text>
                </Button>
              ) : null}
              {task?.taskData?.state === "ReadyToClaim" ||
              task?.taskData?.state === "Started" ? (
                <Button
                  variant="filled"
                  size="sizeM"
                  fullWidth
                  onClick={() => handleClaim(task.taskId)}
                  disabled={
                    task?.taskData?.state !== "ReadyToClaim" ||
                    claimTaskLoading === task.taskId
                  }
                >
                  {claimTaskLoading === task.taskId && <Loader disabled />}
                  <Text variant="textM" weight="semiBold">
                    Claim
                  </Text>
                </Button>
              ) : null}
            </div>
          )}
      </li>
      <TaskDescriptionModal
        showModal={showDescriptionModal}
        setShowModal={setShowDescriptionModal}
        data={task.taskData.taskDetails?.tooltipDescription}
      />
      <VerifyEmail
        showModal={showVerifyModal}
        setShowModal={setShowVerifyModal}
        userData={userData}
        taskData={task}
      />
    </>
  );
};

export default RegisterTask;
