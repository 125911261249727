const generateSalt = (length = 16) => {
  const salt = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    salt[i] = Math.floor(Math.random() * 256);
  }
  return salt;
};

const stringToBytes = (str) => {
  return new TextEncoder().encode(str);
};

const bytesToHex = (bytes) => {
  return Array.from(bytes)
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
};

const xorBytes = (a, b) => {
  const result = new Uint8Array(a.length);
  for (let i = 0; i < a.length; i++) {
    result[i] = a[i] ^ b[i % b.length];
  }
  return result;
};

const generateApiToken = () => {
  const timestamp = Math.floor(Date.now() / 1000).toString();
  const timestampBytes = stringToBytes(timestamp);

  const salt = generateSalt(timestampBytes.length);

  const saltHalf1 = salt.slice(0, Math.ceil(salt.length / 2));
  const saltHalf2 = salt.slice(Math.ceil(salt.length / 2));

  const timestampHalf1 = timestampBytes.slice(
    0,
    Math.ceil(timestampBytes.length / 2),
  );
  const timestampHalf2 = timestampBytes.slice(
    Math.ceil(timestampBytes.length / 2),
  );

  const combined = new Uint8Array(
    saltHalf1.length +
      timestampHalf1.length +
      saltHalf2.length +
      timestampHalf2.length,
  );
  combined.set(saltHalf1, 0);
  combined.set(timestampHalf1, saltHalf1.length);
  combined.set(saltHalf2, saltHalf1.length + timestampHalf1.length);
  combined.set(
    timestampHalf2,
    saltHalf1.length + timestampHalf1.length + saltHalf2.length,
  );

  const xored = xorBytes(combined, salt);

  const saltHex = bytesToHex(salt);

  const xoredHex = bytesToHex(xored);

  const tokenHex = saltHex + xoredHex;

  return tokenHex;
};

export default generateApiToken;
