import styles from "./Balance.module.scss";

import React, { useContext } from "react";
import clsx from "clsx";
import Text from "../base/Text/Text";
import ShineTokenName from "../base/ShineTokenName/ShineTokenName";
import { formatLargeNumber } from "../../utils/strings";

import { TOKEN_NAME } from "../../constants";
import Button from "../base/Button/Button";
import Icon from "../Icon/Icon";
import { DailyBonusModalContext } from "../Modals/DailyBonusModal/DailyBonusModal";

const TokenBalance = ({
  variant = "h2",
  balance,
  className,
  description = `Your ${TOKEN_NAME} Points`,
  formatAmount = true,
  spentDrums = undefined,
  withDailyBonus = true,
}) => {
  const { setIsOpen: setDailyBonusModalOpen } = useContext(
    DailyBonusModalContext,
  );

  return (
    <div
      className={clsx(
        styles.balanceContainer,
        spentDrums !== undefined && styles.balanceContainerWithSpentDrums,
        className,
      )}
    >
      <Text variant="textM" weight="bold" color="green">
        {description}
      </Text>
      <div className={styles.balance}>
        <Text variant={variant} weight="bold">
          {balance ? (formatAmount ? formatLargeNumber(balance) : balance) : 0}
        </Text>
        <ShineTokenName className={styles.tokenName} />
      </div>
      {spentDrums !== undefined && (
        <div className={styles.spentDrums}>
          <Text variant="textXM" weight="semiBold" color="green">
            DRUMs spent:
          </Text>{" "}
          <Text variant="textXM" weight="bold">
            {formatLargeNumber(spentDrums)}
          </Text>
        </div>
      )}
      {withDailyBonus && (
        <Button
          type="icon"
          variant="filledGift"
          size="size0"
          className={styles.dailyBonusButton}
          onClick={() => setDailyBonusModalOpen(true)}
        >
          <Icon
            name="gift"
            width={24}
            height={24}
            className={styles.giftIcon}
          />
        </Button>
      )}
    </div>
  );
};

export default TokenBalance;
