import { useState, useCallback, useEffect, useRef } from "react";

export const useDrumSound = (drumId) => {
  const [playbackRate, setPlaybackRate] = useState(0.5);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const audioContextRef = useRef(null);
  const sourceBufferRef = useRef(null);
  const gainNodeRef = useRef(null);
  const loadedSoundRef = useRef(null);
  const getSoundFile = (id) => {
    switch (id) {
      case 0:
        return "/drum.mp3";
      case 1:
      case 2:
      case 3:
      case 4:
        return "/drum-bull.mp3";
      case 5:
      case 6:
      case 7:
        return "/drum-wolf.mp3";
      case 8:
      case 9:
      case 10:
        return "/drum-wolf.mp3";
      default:
        return "/drum.mp3";
    }
  };
  useEffect(() => {
    if (drumId === null) return;

    const soundFile = getSoundFile(drumId);

    const loadSound = () => {
      setIsLoading(true);
      setError(null);

      const xhr = new XMLHttpRequest();
      xhr.open("GET", soundFile, true);
      xhr.responseType = "arraybuffer";

      xhr.onload = () => {
        if (xhr.status === 200) {
          if (!audioContextRef.current) {
            try {
              const AudioContext =
                window.AudioContext || window.webkitAudioContext;
              if (!AudioContext) {
                throw new Error(
                  "AudioContext is not supported in this browser",
                );
              }
              audioContextRef.current = new AudioContext();
              gainNodeRef.current = audioContextRef.current.createGain();
              gainNodeRef.current.connect(audioContextRef.current.destination);
            } catch (err) {
              setError(`Failed to create AudioContext: ${err.message}`);
              setIsLoading(false);
              return;
            }
          }

          audioContextRef.current.decodeAudioData(
            xhr.response,
            (buffer) => {
              sourceBufferRef.current = buffer;
              loadedSoundRef.current = soundFile;
              setIsLoading(false);
            },
            (err) => {
              setError("Not able to decode audio data");
              setIsLoading(false);
            },
          );
        } else {
          setError("Failed to load sound");
          setIsLoading(false);
        }
      };

      xhr.onerror = () => {
        setError("Network error");
        setIsLoading(false);
      };

      xhr.send();
    };

    if (loadedSoundRef.current !== soundFile) {
      loadSound();
    } else {
      setIsLoading(false);
    }

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close().catch(console.error);
        audioContextRef.current = null;
      }
    };
  }, [drumId]);

  const playDrum = useCallback(() => {
    if (audioContextRef.current && sourceBufferRef.current && !isLoading) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = sourceBufferRef.current;
      source.playbackRate.value = playbackRate;
      source.connect(gainNodeRef.current);

      source.start(0);
      setPlaybackRate((prevRate) => Math.min(prevRate + 0.02, 1.7));
    }
  }, [playbackRate, isLoading]);

  const resetPlaybackRate = useCallback(() => {
    setPlaybackRate(0.5);
  }, []);

  return { playDrum, resetPlaybackRate, playbackRate, isLoading, error };
};
