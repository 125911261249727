import styles from "./ProgressBar.module.scss";
import React from "react";
import clsx from "clsx";

const ProgressBar = ({ level, maxLevel = 100, className }) => {
  const progressPercentage = Math.min((level / maxLevel) * 100, 100);

  return (
    <div className={clsx(styles.progressBar, className)}>
      <div
        className={styles.progressBarFill}
        style={{ width: `${progressPercentage}%` }}
      />
    </div>
  );
};

export default ProgressBar;
