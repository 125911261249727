import { useContext } from "react";

import { NotificationsContext } from "../../../Modals/NotificationsModal/NotificationsModal";

export const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error(
      "useNotifications must be used within a NotificationsModalProvider",
    );
  }

  return context;
};
