import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import useApi from "../api/rest";
import { useTelegram } from "./TelegramProvider";
import { DateTime } from "luxon";
import { isEqual } from "lodash";

const initialContext = {};

export const AppContext = createContext(initialContext);

export const AppProvider = ({ children }) => {
  const { webApp } = useTelegram();
  const [showScreen, setShowScreen] = useState("tap");
  const { getUserInfo } = useApi();
  const [userInfo, setUserInfo] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successModalData, setSuccessModalData] = useState(null);
  const [isClaimFarmStarted, setIsClaimFarmStarted] = useState(false);
  const [tapsState, setTapsState] = useState({
    tapsLeft: null,
    tapsCount: 0,
    amount: 0,
    currentTapWindowFinishIn: null,
    currentTapWindowFinishAt: null,
  });
  const [tapsTimeLeftInMillis, setTapsTimeLeftInMillis] = useState(null);

  const isPlatformAllowed = true;

  const updateUserInfo = useCallback(() => {
    getUserInfo().then((data) => {
      if (data.status === "error") {
      } else {
        if (!isEqual(userInfo, data)) {
          setUserInfo(data);
        }
      }
    });
  }, [getUserInfo, userInfo]);

  useEffect(() => {
    if (!userInfo && webApp && webApp.initData && isPlatformAllowed) {
      webApp.expand();
      webApp.onEvent("viewportChanged", () => {
        if (!webApp.isExpanded) {
          webApp.expand();
        }
      });
      updateUserInfo();
    }
  }, [webApp, userInfo, updateUserInfo, isPlatformAllowed]);

  useEffect(() => {
    let timerId;
    if (webApp && webApp.initData && isPlatformAllowed) {
      timerId = setInterval(updateUserInfo, 300000);
    }
    return () => clearInterval(timerId);
  }, [webApp, updateUserInfo, isPlatformAllowed]);

  useEffect(() => {
    if (userInfo && !userInfo.email) {
      setShowScreen("email");
    }
  }, [userInfo]);

  const updateTapsTimeLeft = useCallback(() => {
    if (tapsState.currentTapWindowFinishAt) {
      const duration = DateTime.fromMillis(tapsState.currentTapWindowFinishAt)
        .diffNow()
        .toMillis();
      setTapsTimeLeftInMillis(duration > 0 ? duration : null);
    }
  }, [tapsState.currentTapWindowFinishAt]);

  useEffect(() => {
    let timerId;
    if (userInfo && tapsTimeLeftInMillis !== null) {
      timerId = setInterval(updateTapsTimeLeft, 1000);
    }
    return () => clearInterval(timerId);
  }, [userInfo, tapsTimeLeftInMillis, updateTapsTimeLeft]);

  useEffect(() => {
    if (userInfo) {
      setTapsState((prev) => {
        let newState = { ...prev };
        let hasChanged = false;

        if (prev.tapsLeft === null || prev.tapsCount === 0) {
          newState.tapsLeft = userInfo.availableTaps;
          hasChanged = true;
        }

        if (
          userInfo.currentTapWindowFinishIn !== null &&
          userInfo.currentTapWindowFinishAt !== null &&
          tapsTimeLeftInMillis === null
        ) {
          newState.currentTapWindowFinishIn = userInfo.currentTapWindowFinishIn;
          newState.currentTapWindowFinishAt = userInfo.currentTapWindowFinishAt;
          setTapsTimeLeftInMillis(userInfo.currentTapWindowFinishIn);
          hasChanged = true;
        }

        if (hasChanged && !isEqual(prev, newState)) {
          return newState;
        } else {
          return prev;
        }
      });
    }
  }, [userInfo, tapsTimeLeftInMillis]);

  const setters = useMemo(
    () => ({
      setShowScreen,
      setUserInfo,
      setShowSuccessModal,
      setSuccessModalData,
      setTapsState,
      setIsClaimFarmStarted,
      setTapsTimeLeftInMillis,
    }),
    [],
  );

  const value = useMemo(
    () => ({
      showScreen,
      userInfo,
      showSuccessModal,
      successModalData,
      tapsState,
      isClaimFarmStarted,
      tapsTimeLeftInMillis,
      ...setters,
    }),
    [
      showScreen,
      userInfo,
      showSuccessModal,
      successModalData,
      tapsState,
      isClaimFarmStarted,
      tapsTimeLeftInMillis,
      setters,
    ],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useApp = () => useContext(AppContext);
