import { useEffect, useRef, useCallback } from "react";
import useApi from "./../api/rest";

const ErrorHandler = ({ children }) => {
  const { sendLog } = useApi();
  const logsRef = useRef([]);

  useEffect(() => {
    console.log("ErrorBoundaryWrapper mounted");
    const originalLog = console.log;
    const originalWarn = console.warn;
    const originalError = console.error;

    const captureLog =
      (type) =>
      (...args) => {
        logsRef.current.push({
          type,
          args,
          timestamp: new Date().toISOString(),
        });
        if (logsRef.current.length > 20) {
          logsRef.current.shift();
        }

        if (type === "log") return originalLog(...args);
        if (type === "warn") return originalWarn(...args);
        return originalError(...args);
      };

    console.log = captureLog("log");
    console.warn = captureLog("warn");
    console.error = captureLog("error");

    return () => {
      console.log = originalLog;
      console.warn = originalWarn;
      console.error = originalError;
    };
  }, []);

  const getRecentConsoleLogs = useCallback(() => {
    return logsRef.current;
  }, []);

  const shouldSendError = useCallback((error) => {
    const errorKey = `${error.message}|${error.stack}`;
    const now = Date.now();
    const errorCache = JSON.parse(localStorage.getItem("errorCache") || "{}");
    const lastSent = errorCache[errorKey];

    if (lastSent && now - lastSent < 900000) {
      return false;
    }

    errorCache[errorKey] = now;
    localStorage.setItem("errorCache", JSON.stringify(errorCache));
    return true;
  }, []);

  useEffect(() => {
    const handleGlobalError = (event) => {
      event.preventDefault();
      handleError(event.error);
    };

    const handleUnhandledRejection = (event) => {
      event.preventDefault();
      handleError(event.reason);
    };

    const handleError = (error) => {
      console.error("Caught error:", error?.stack || error?.message || error);

      alert(`We've identified an issue with the game.\n
A report regarding the crash has been submitted to the Wigwam team for investigation.
\n\nPlease try again later.`);

      if (shouldSendError(error)) {
        sendLog({
          type: "Error Handler",
          message: error.message,
          stack: error.stack,
          url: window.location.href,
          timestamp: new Date().toISOString(),
          consoleLog: getRecentConsoleLogs(),
        });
      }
    };

    window.addEventListener("error", handleGlobalError);
    window.addEventListener("unhandledrejection", handleUnhandledRejection);

    return () => {
      window.removeEventListener("error", handleGlobalError);
      window.removeEventListener(
        "unhandledrejection",
        handleUnhandledRejection,
      );
    };
  }, [sendLog, getRecentConsoleLogs, shouldSendError]);

  return children;
};

export default ErrorHandler;
