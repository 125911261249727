import React from "react";

import ModalWrapper from "../../../base/ModalWrapper/ModalWrapper";
import Text from "../../../base/Text/Text";
import ProgressBar from "../../../base/ProgressBar/ProgressBar";
import SkillIcon from "./SkillIcon";

import styles from "./SkillInfoModal.module.scss";
import { formatAmount } from "../utils";

export const SkillInfoModal = React.memo(
  ({ skill, onClose }) => {
    if (!skill) {
      return null;
    }

    return (
      <ModalWrapper
        onClose={onClose}
        className={styles.skillInfoModal}
        backgroundClassName={styles.backgroundWrapper}
        closeButtonPosition="inline"
      >
        <div className={styles.content}>
          <div className={styles.iconWrapper}>
            <SkillIcon
              name={skill.skillId ?? ""}
              className={styles.skillIcon}
            />
          </div>

          <div className={styles.infoBlock}>
            <Text
              variant="h1"
              weight="bold"
              color="gradient"
              className={styles.skillName}
              tag="div"
            >
              {skill?.name ?? "Unknown Skill"}
            </Text>
            <Text
              variant="textM"
              weight="bold"
              className={styles.skillBonus}
              tag="div"
            >
              +{formatAmount(skill.drumsPerDay ?? 0)} DRUMS/Day
            </Text>
            <ProgressBar
              level={skill?.level ?? 50}
              maxLevel={100}
              className={styles.skillLevelBar}
            />
          </div>
          <Text variant="textL" className={styles.description}>
            {skill.description ?? "No description available for this skill."}
          </Text>
          <div className={styles.descriptionBlock}></div>
        </div>
      </ModalWrapper>
    );
  },
  (prevProps, nextProps) => prevProps.skill === nextProps.skill,
);

export const SkillInfoModalContext = React.createContext(null);

export const SkillInfoModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedSkill, setSelectedSkill] = React.useState(null);

  const handleModalState = (val, skill = null) => {
    setIsOpen(val);
    if (val) {
      setSelectedSkill(skill);
      if (typeof window !== "undefined") {
        window.scrollTo(0, 0);
      }
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  return (
    <SkillInfoModalContext.Provider
      value={{ isOpen, setIsOpen: handleModalState }}
    >
      {children}
      {isOpen && (
        <SkillInfoModal
          skill={selectedSkill}
          onClose={() => handleModalState(false)}
        />
      )}
    </SkillInfoModalContext.Provider>
  );
};
