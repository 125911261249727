import React from "react";

const SkillIcon = ({ name, width = "24px", height = "24px", className }) => {
  const personalSkills = [
    "WarriorTraining",
    "ElderWisdom",
    "StorytellingSkills",
    "SpiritualPower",
    "TribalDance",
    "RhythmSkills",
    "DrumCraftsmanship",
    "HealerSkills",
    "HuntingSkills",
    "ToolCrafting",
  ];

  const ceremonialSkills = [
    "SacredPaintings",
    "FestivalDecorations",
    "CeremonialDrums",
    "MusicalEnsemble",
    "AnimalCompanions",
    "CostumeAndAccessories",
    "SpiritualJourneys",
    "MusicComposition",
    "MythicalCreatures",
    "EnergyManipulation",
  ];

  const getImagePaths = (skillName) => {
    let basePath;
    if (personalSkills.includes(skillName)) {
      basePath = `/img/skills/personal/${skillName}`;
    } else if (ceremonialSkills.includes(skillName)) {
      basePath = `/img/skills/ceremonial/${skillName}`;
    } else {
      console.warn(`Unknown skill: ${skillName}`);
      return {
        avif: "/img/skills/unknown-skill.avif",
        png: "/img/skills/unknown-skill.png",
      };
    }
    return {
      avif: `${basePath}.avif`,
      png: `${basePath}.png`,
    };
  };

  const { avif, png } = getImagePaths(name);

  return (
    <picture>
      <source srcSet={avif} type="image/avif" />
      <source srcSet={png} type="image/png" />
      <img
        src={png}
        alt={`${name} skill icon`}
        width={width}
        height={height}
        className={className}
      />
    </picture>
  );
};

export default SkillIcon;
