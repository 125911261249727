import React, { useRef, useCallback, useContext } from "react";
import ReactDOM from "react-dom";
import styles from "./SkillList.module.scss";
import clsx from "clsx";
import Loader from "../../../Loader/Loader";
import Text from "../../../base/Text/Text";
import Button from "../../../base/Button/Button";
import ProgressBar from "../../../base/ProgressBar/ProgressBar";
import SkillIcon from "./SkillIcon";
import { formatAmount } from "./../utils";
import { formatLargeNumber } from "./../../../../utils/strings";
import { SkillInfoModalContext } from "./SkillInfoModal";
import ConfettiExplosion from "react-confetti-explosion";

const ConfettiPortal = ({ isActive, position, modalRef }) => {
  const confettiProps = {
    force: 0.8,
    duration: 3000,
    particleCount: 60,
    width: 1600,
    spread: 90,
    angle: 90,
    zIndex: 1000,
  };

  if (!isActive || !modalRef?.current || !position) return null;

  return ReactDOM.createPortal(
    <div
      style={{
        position: "fixed",
        top: position.top,
        left: position.left,
        transform: "translate(-50%, -50%)",
        pointerEvents: "none",
        zIndex: 1000,
      }}
    >
      <ConfettiExplosion {...confettiProps} />
    </div>,
    modalRef.current,
  );
};

const Skill = React.memo(
  ({
    skillId,
    skill,
    onUpgrade,
    canUpgradeSkill,
    isUpgrading,
    getUpgradeButtonText,
  }) => {
    const buttonRef = useRef(null);
    const { setIsOpen: setSkillInfoModalOpen } = useContext(
      SkillInfoModalContext,
    );

    const handleUpgradeClick = useCallback(() => {
      if (buttonRef.current) {
        const rect = buttonRef.current.getBoundingClientRect();
        const scrollTop = window.scrollY ?? document.documentElement.scrollTop;
        const scrollLeft =
          window.scrollX ?? document.documentElement.scrollLeft;

        const position = {
          top: rect.top + rect.height / 2 + scrollTop,
          left: rect.left + rect.width / 2 + scrollLeft,
        };
        onUpgrade(skillId, skill, position);
      } else {
        onUpgrade(skillId, skill);
      }
    }, [onUpgrade, skillId, skill]);

    const handleSkillInfoClick = useCallback(
      (e) => {
        e.stopPropagation();
        setSkillInfoModalOpen(true, { ...skill, skillId });
      },
      [setSkillInfoModalOpen, skill, skillId],
    );

    return (
      <div className={styles.skillItem}>
        <div className={styles.skillLevel}>
          <Text
            tag="div"
            variant="textS"
            weight="bold"
            opacity="0.8"
            className={clsx(
              styles.skillLevelText,
              (skill?.level ?? 0) >= 10 && styles.skillLevelTextWide,
            )}
          >
            Lv.{skill?.level ?? 0}
          </Text>
        </div>
        <div className={styles.iconWrapper}>
          <Button
            type="icon"
            size="size0"
            className={styles.skillIconBtn}
            onClick={handleSkillInfoClick}
          >
            <SkillIcon
              name={skillId ?? ""}
              width="48px"
              height="48px"
              className={styles.skillIcon}
            />
          </Button>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.skillHeader}>
            <div className={styles.skillInfo}>
              <span className={styles.skillName}>
                {skill?.name ?? "Unknown Skill"}
              </span>
              <span className={styles.skillBonus}>
                +{formatAmount(skill?.drumsPerDay ?? 0)} DRUMS/Day
              </span>
            </div>
            <div ref={buttonRef}>
              <Button
                variant="filledAccent"
                onClick={handleUpgradeClick}
                ariaDisabled={!canUpgradeSkill || isUpgrading}
                size="sizeS"
                className={styles.upgradeSkillButton}
              >
                {isUpgrading ? (
                  <Loader size="small" />
                ) : (
                  <Text
                    tag="div"
                    variant="textXM"
                    weight="semiBold"
                    opacity="0.8"
                  >
                    {getUpgradeButtonText(skill)}
                  </Text>
                )}
              </Button>
            </div>
          </div>
          <div className={styles.skillFooter}>
            <ProgressBar
              level={skill?.level ?? 0}
              maxLevel={100}
              className={styles.skillLevelBar}
            />
            <div className={styles.upgradeCost}>
              <img
                src="/img/drumCoin.png"
                alt="drum-coin"
                width="18px"
                className={styles.coinIcon}
              />
              <Text variant="textXM" weight="semiBold" color="gradient">
                {formatLargeNumber(skill?.nextLevelUpgradeCost ?? 0)}
              </Text>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

const SkillList = React.memo(
  ({
    filteredSkills = [],
    onUpgrade,
    upgradingSkills = {},
    confettiSkill,
    confettiPosition,
    getUpgradeButtonText,
  }) => {
    const modalRef = useRef(document.body);

    return (
      <div className={styles.skillsList}>
        {filteredSkills.map(({ skillId, skill, canUpgrade }) => (
          <div key={skillId ?? "unknown"} className={styles.skillItemWrapper}>
            <Skill
              skillId={skillId}
              skill={skill}
              onUpgrade={onUpgrade}
              canUpgradeSkill={canUpgrade ?? false}
              isUpgrading={upgradingSkills[skillId] ?? false}
              getUpgradeButtonText={getUpgradeButtonText}
            />
            {confettiSkill === skillId && confettiPosition && (
              <ConfettiPortal
                isActive={true}
                position={confettiPosition}
                modalRef={modalRef}
              />
            )}
          </div>
        ))}
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.filteredSkills === nextProps.filteredSkills &&
      prevProps.upgradingSkills === nextProps.upgradingSkills
    );
  },
);

export default SkillList;
