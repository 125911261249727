// hooks/useAdsgram.js
import { useCallback, useEffect, useRef } from "react";

export function useAdsgram({ blockId, onReward, onError }) {
  const AdControllerRef = useRef(undefined);

  useEffect(() => {
    AdControllerRef.current = window.Adsgram?.init({ blockId });
  }, [blockId]);

  return useCallback(async () => {
    if (AdControllerRef.current) {
      AdControllerRef.current
        .show()
        .then((result) => {
          // user watched ad till the end
          onReward(result);
        })
        .catch((result) => {
          // user got error during playing ad or skipped ad
          onError?.(result);
        });
    } else {
      onError?.({
        error: true,
        done: false,
        state: "load",
        description: "Adsgram script not loaded",
      });
    }
  }, [onError, onReward]);
}
