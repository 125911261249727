import React, { useState, useRef, useCallback, useEffect } from "react";
import clsx from "clsx";

import ModalWrapper from "../../base/ModalWrapper/ModalWrapper";
import Text from "../../base/Text/Text";
import styles from "./NotificationsModal.module.scss";

import useApi from "../../../api/rest";
import { useTelegram } from "../../../context/TelegramProvider";

const formatTimeAgo = (timestamp) => {
  const now = Date.now();
  const date =
    typeof timestamp === "number" && timestamp.toString().length === 10
      ? timestamp * 1000
      : timestamp;

  const diff = now - date;
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 30) {
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);
    if (years > 0) {
      return `${years} year${years > 1 ? "s" : ""} ago`;
    }
    return `${months} month${months > 1 ? "s" : ""} ago`;
  }
  if (days > 0) return `${days} day${days > 1 ? "s" : ""} ago`;
  if (hours > 0) return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  if (minutes > 0) return `${minutes} min ago`;
  if (seconds > 10) return `${seconds} sec ago`;
  return "Just now";
};

const NotificationPost = ({ post, onRead }) => {
  const { webApp } = useTelegram();

  const handleClick = () => {
    if (post?.redirectUrl) {
      try {
        webApp.openTelegramLink(post.redirectUrl);
        onRead(post.createdAt);
      } catch (error) {
        console.error("Failed to open Telegram link:", error);
        window.open(post.redirectUrl, "_blank");
      }
    }
  };

  return (
    <div
      className={`${styles.post} ${post.isNew ? styles.newPost : ""}`}
      onClick={handleClick}
    >
      <div className={styles.postHeader}>
        <Text
          variant="textM"
          weight="bold"
          className={clsx(
            styles.postTitle,
            !post.isNew ? styles.opacity6 : styles.new,
          )}
        >
          {post.chatTitle}
        </Text>
        <Text
          variant="textM"
          weight="bold"
          className={clsx(styles.postTime, !post.isNew && styles.opacity6)}
        >
          {formatTimeAgo(post.createdAt)}
        </Text>
      </div>
      <Text
        variant="textM"
        weight="slim"
        align="left"
        className={clsx(styles.postText, !post.isNew && styles.opacity6)}
        tag="div"
      >
        {post.text}
      </Text>
    </div>
  );
};

export const NotificationsModal = React.memo(
  ({ onClose, posts, onRead }) => {
    const [localPosts, setLocalPosts] = useState(posts);
    const initialRenderRef = useRef(true);
    const latestReadTimestampRef = useRef(0);

    useEffect(() => {
      if (initialRenderRef.current) {
        if (posts.length > 0) {
          const latestPostTimestamp = Math.max(
            ...posts.map((post) => post.createdAt),
          );
          latestReadTimestampRef.current = latestPostTimestamp;
        }
        initialRenderRef.current = false;
      }

      return () => {
        onRead(latestReadTimestampRef.current);
      };
    }, [posts, onRead]);

    const handlePostRead = (timestamp) => {
      setLocalPosts((prevPosts) =>
        prevPosts.map((post) => ({
          ...post,
          isNew: post.createdAt > timestamp,
        })),
      );
      latestReadTimestampRef.current = Math.max(
        latestReadTimestampRef.current,
        timestamp,
      );
      onRead(timestamp);
    };

    return (
      <ModalWrapper
        onClose={() => {
          onRead(latestReadTimestampRef.current);
          onClose();
        }}
        className={styles.notificationsModal}
        backgroundClassName={styles.backgroundWrapper}
        header={<br />}
      >
        <div className={styles.content}>
          <Text
            variant="h1"
            weight="bold"
            color="gradient"
            className={styles.title}
            tag="div"
          >
            Notifications
          </Text>
          <div className={styles.postList}>
            {localPosts.length > 0 ? (
              localPosts.map((post) => (
                <NotificationPost
                  key={post.messageId}
                  post={post}
                  onRead={handlePostRead}
                />
              ))
            ) : (
              <Text
                variant="textXL"
                weight="bold"
                className={styles.noNotifications}
                tag="div"
              >
                No new notifications yet
              </Text>
            )}
          </div>
        </div>
      </ModalWrapper>
    );
  },
  (prevProps, nextProps) => prevProps.posts === nextProps.posts,
);

export const NotificationsContext = React.createContext(null);

export const NotificationsModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [posts, setPosts] = useState([]);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const syncedRef = useRef(false);
  const { user } = useTelegram();
  const { getPosts } = useApi();

  const syncPosts = useCallback(async () => {
    if (syncedRef.current) return;
    try {
      const fetchedPosts = await getPosts();
      const lastViewedTimestamp = parseInt(
        localStorage.getItem("lastViewedPostTimestamp") || "0",
      );

      const updatedPosts = fetchedPosts.map((post) => ({
        ...post,
        isNew: post.createdAt > lastViewedTimestamp,
      }));

      setPosts(updatedPosts);
      setHasNewNotifications(updatedPosts.some((post) => post.isNew));
      syncedRef.current = true;
    } catch (error) {
      console.error("Failed to fetch posts:", error);
    }
  }, [getPosts]);

  const handleModalState = (val) => {
    setIsOpen(val);
    if (val) {
      if (typeof window !== "undefined") {
        window.scrollTo(0, 0);
      }
    }
  };

  const handleReadNotification = (timestamp) => {
    localStorage.setItem("lastViewedPostTimestamp", timestamp.toString());
    const updatedPosts = posts.map((post) => ({
      ...post,
      isNew: post.createdAt > timestamp,
    }));
    setPosts(updatedPosts);
    setHasNewNotifications(updatedPosts.some((post) => post.isNew));
  };

  useEffect(() => {
    if (!user?.id) {
      return;
    }

    syncPosts();
  }, [syncPosts, user?.id]);

  return (
    <NotificationsContext.Provider
      value={{
        isOpen,
        setIsOpen: handleModalState,
        posts,
        syncPosts,
        hasNewNotifications,
      }}
    >
      {children}
      {isOpen && (
        <NotificationsModal
          onClose={() => handleModalState(false)}
          posts={posts}
          onRead={handleReadNotification}
        />
      )}
    </NotificationsContext.Provider>
  );
};
