import styles from "./Team.module.scss";
import { useEffect, useState, useRef, useCallback } from "react";

import { useTelegram } from "../../../context/TelegramProvider";
import { useApp } from "../../../context/AppProvider";
import useApi from "../../../api/rest";
import Text from "../../base/Text/Text";
import Button from "../../base/Button/Button";
import Loader from "../../Loader/Loader";
import { TOKEN_NAME } from "../../../constants";
import Icon from "../../Icon/Icon";
import { formatLargeNumber, formatNumberForUi } from "../../../utils/strings";
import ShineTokenName from "../../base/ShineTokenName/ShineTokenName";
import clsx from "clsx";

import Balance from "../../Balance/Balance";

const Team = () => {
  const { webApp } = useTelegram();
  const { userInfo, setUserInfo, setShowSuccessModal, setSuccessModalData } =
    useApp();

  const { getChildren, claimFromChildren } = useApi();
  const [isClaimStarted, setIsClaimStarted] = useState(false);
  const [childrenData, setChildrenData] = useState({
    children: [],
    totalBalance: "0.0000",
    totalRewardsToClaim: "0.0000",
    cursor: undefined,
    totalChildrenCount: 0,
    childrenCount: 0,
  });
  const isMounted = useRef(false);
  const [message, setMessage] = useState({ show: false, text: "" });

  const showMessageHandler = useCallback((text) => {
    setMessage({ show: true, text });
    setTimeout(() => setMessage({ show: false, text: "" }), 3000);
  }, []);

  const claim = useCallback(async () => {
    setIsClaimStarted(true);
    try {
      const response = await claimFromChildren();
      if (response.status === "error") {
        const reason = response.error?.response?.data?.data?.reason ?? "";
        const reasonMsg = reason ? `: ${reason}` : "";

        showMessageHandler(
          "An error occurred while claiming rewards" + reasonMsg,
        );
        return;
      }
      setUserInfo((prevUserInfo) => ({
        ...prevUserInfo,
        balance: response.data?.balance ?? prevUserInfo?.balance ?? 0,
      }));
      if (parseFloat(childrenData.totalRewardsToClaim) > 0) {
        setShowSuccessModal(true);
        setSuccessModalData({
          reward: (
            <Text variant="h1" weight="bold">
              +{formatNumberForUi(response.data?.claimedBalance ?? 0)}{" "}
              <ShineTokenName />
            </Text>
          ),
          description: (
            <Text variant="textL">
              Stay bright, keep the fight,
              <br />
              In every challenge, find the light.
              <br />
              Earn more {TOKEN_NAME}!
            </Text>
          ),
        });
      }
      const resp = await getChildren();
      if (resp.status === "error") {
        console.error("getChildrenErr", resp.error);
        const reason = response.error?.response?.data?.data?.reason ?? "";
        const reasonMsg = reason ? `: ${reason}` : "";
        showMessageHandler(
          "An error occurred while updating children data" + reasonMsg,
        );
        return;
      }

      resp.data.children?.sort((a, b) => (b.balance ?? 0) - (a.balance ?? 0));

      setChildrenData(resp.data);
    } catch (error) {
      console.error("Error during claim:", error);
      showMessageHandler("An unexpected error occurred" + error.message ?? "");
    } finally {
      setIsClaimStarted(false);
    }
  }, [
    claimFromChildren,
    getChildren,
    childrenData.totalRewardsToClaim,
    setUserInfo,
    setShowSuccessModal,
    setSuccessModalData,
    showMessageHandler,
  ]);

  const handleInviteFriends = useCallback(() => {
    try {
      webApp?.openTelegramLink(userInfo?.inviteUrl ?? "");
    } catch (error) {
      console.error("Failed to open telegram link:", error, userInfo);
      showMessageHandler("Failed to open telegram link");
    }
  }, [webApp, showMessageHandler, userInfo]);

  const handleShare = useCallback(() => {
    const shareData = {
      title: userInfo?.shareDetails?.text ?? "",
      text: userInfo?.shareDetails?.text ?? "",
    };

    if (webApp?.platform !== "ios") {
      shareData.url = userInfo?.shareDetails?.url ?? "";
    }

    if (navigator?.share) {
      navigator
        .share(shareData)
        .then(() => {
          console.log("Content shared successfully");
        })
        .catch((error) => {
          console.error("Failed to share content:", error);
          showMessageHandler("Failed to share content");
        });
    } else {
      webApp?.openTelegramLink(userInfo?.inviteUrl ?? "");
    }
  }, [webApp, userInfo?.shareDetails, userInfo?.inviteUrl, showMessageHandler]);

  useEffect(() => {
    if (!isMounted.current) {
      getChildren().then((response) => {
        if (response.status === "error") {
          console.error("useEffect-getChildrenErr", response.error);
          const reason = response.error?.response?.data?.data?.reason ?? "";
          const reasonMsg = reason ? `: ${reason}` : "";
          showMessageHandler("Failed to fetch children data" + reasonMsg);
          return;
        }

        response.data.children?.sort(
          (a, b) => (b.balance ?? 0) - (a.balance ?? 0),
        );
        setChildrenData(response.data);
      });
      isMounted.current = true;
    }
  }, [getChildren, showMessageHandler]);

  const withCursor = childrenData.cursor !== undefined;

  return (
    <div className={styles.container}>
      <Text variant="h2" weight="bold">
        More in your tribe, more{" "}
        <Text variant="h2" weight="bold" color="gradient">
          {TOKEN_NAME}s
        </Text>{" "}
        and fun to vibe!
      </Text>
      <Balance
        variant="h1"
        balance={
          formatLargeNumber(parseFloat(childrenData.totalBalance ?? "0")) +
          (withCursor ? "+" : "")
        }
        description="Total Tribe balance"
        formatAmount={false}
        className={clsx(styles.contentContainer, styles.balanceContainer)}
      />
      <div className={styles.contentContainer}>
        <div className={styles.financeContainer}>
          <Text variant="textL" color="green" weight="bold">
            Your rewards
          </Text>
          <Text variant="h1" weight="bold">
            {formatNumberForUi(childrenData.totalRewardsToClaim ?? "0")}
            {withCursor ? "+" : ""} <ShineTokenName />
          </Text>
          {!withCursor && (
            <Text variant="textL" className={styles.financeDescr}>
              10% of your friends' rewards
            </Text>
          )}
        </div>
        {withCursor && (
          <div className={styles.progressWrapper}>
            <div className={styles.progressHeader}>Claimed:</div>
            <div className={styles.progressInfo}>
              <span>{childrenData.cursor ?? 0}</span>
              <span>of {childrenData.totalChildrenCount ?? 0} users</span>
            </div>
            <div className={styles.progress}>
              <div
                className={styles.progressInner}
                style={{
                  width: `${
                    ((childrenData.cursor ?? 0) /
                      (childrenData.totalChildrenCount ?? 1)) *
                    100
                  }%`,
                }}
              />
            </div>
          </div>
        )}
        <Button
          variant="filledSec"
          onClick={claim}
          disabled={
            (parseFloat(childrenData.totalRewardsToClaim ?? "0") === 0 &&
              !withCursor) ||
            isClaimStarted
          }
          className={styles.claimButton}
        >
          {isClaimStarted && <Loader disabled={isClaimStarted} />}
          <Text variant="textXL" weight="bold">
            {parseFloat(childrenData.totalRewardsToClaim ?? "0") === 0 &&
            withCursor
              ? "Update"
              : "Claim"}
          </Text>
        </Button>
      </div>
      <div className={styles.teamWrapper}>
        <div className={styles.teamContainer}>
          <div className={styles.team}>
            <Icon name="users" width="24px" height="24px" color="white" />
            <Text variant="textL">Your team</Text>
          </div>
          <Text variant="textL">
            {childrenData.totalChildrenCount ??
              childrenData.children?.length ??
              0}{" "}
            user{(childrenData.children?.length ?? 0) !== 1 ? "s" : ""}
          </Text>
        </div>
        <div className={styles.teamList}>
          {childrenData.children?.map((child, index) => (
            <div key={index} className={styles.member}>
              <div className={styles.wrapper}>
                <div className={styles.name}>
                  <Icon name="user" width="16px" height="16px" />
                  <Text variant="textM" weight="bold">
                    {child.userName ?? "Unknown"}
                  </Text>
                </div>
                <Text variant="textM" weight="bold">
                  {formatLargeNumber(child.balance ?? 0)}{" "}
                  <Text variant="textM" weight="bold" color="gradient">
                    {TOKEN_NAME}
                  </Text>
                </Text>
              </div>
            </div>
          ))}
        </div>
        <div
          className={clsx(
            styles.shareWrapper,
            withCursor ? styles.withCursor : "",
          )}
        >
          {withCursor && (
            <div className={styles.shareMoreUsers}>
              +
              {(childrenData.totalChildrenCount ?? 0) -
                (childrenData.childrenCount ?? 0)}{" "}
              users
            </div>
          )}
          <div className={styles.shareWrapperInner}>
            <Button
              variant="filledSec"
              fullWidth
              onClick={handleInviteFriends}
              className={styles.inviteButton}
            >
              <Text variant="textXL" weight="bold">
                Invite friends
              </Text>
            </Button>

            <Button className={styles.shareButton} onClick={handleShare}>
              <Icon name="share" width="20px" height="20px" color="#FFFFFF" />
            </Button>
          </div>
        </div>
      </div>
      {/* Popup message */}
      {message.show && (
        <div className={styles.popupMessage}>
          <Text variant="textXM" weight="bold">
            {message.text}
          </Text>
        </div>
      )}
    </div>
  );
};

export default Team;
