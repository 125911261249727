import { useCallback, useEffect, useRef } from "react";

export const useErrorHandler = (sendLog) => {
  const logsRef = useRef([]);

  useEffect(() => {
    const originalLog = console.log;
    const originalWarn = console.warn;
    const originalError = console.error;

    const captureLog =
      (type) =>
      (...args) => {
        logsRef.current.push({
          type,
          args,
          timestamp: new Date().toISOString(),
        });
        if (logsRef.current.length > 20) {
          logsRef.current.shift();
        }

        if (type === "log") return originalLog(...args);
        if (type === "warn") return originalWarn(...args);
        return originalError(...args);
      };

    console.log = captureLog("log");
    console.warn = captureLog("warn");
    console.error = captureLog("error");

    return () => {
      console.log = originalLog;
      console.warn = originalWarn;
      console.error = originalError;
    };
  }, []);

  const getPerformanceMetrics = useCallback(() => {
    if (window.performance) {
      const navigation = window.performance.getEntriesByType("navigation")[0];
      const paint = window.performance.getEntriesByType("paint");
      return {
        loadTime: navigation.loadEventEnd - navigation.startTime,
        domContentLoaded:
          navigation.domContentLoadedEventEnd - navigation.startTime,
        firstPaint: paint.find((entry) => entry.name === "first-paint")
          ?.startTime,
        firstContentfulPaint: paint.find(
          (entry) => entry.name === "first-contentful-paint",
        )?.startTime,
      };
    }
    return null;
  }, []);

  const getRecentNetworkCalls = useCallback(() => {
    if (window.performance) {
      return window.performance
        .getEntriesByType("resource")
        .slice(-10)
        .map((entry) => ({
          name: entry.name,
          duration: entry.duration,
          startTime: entry.startTime,
          initiatorType: entry.initiatorType,
        }));
    }
    return [];
  }, []);

  const getRecentConsoleLogs = useCallback(() => {
    return logsRef.current;
  }, []);

  const gatherAdditionalInfo = useCallback(() => {
    return {
      url: window.location.href,
      userAgent: navigator.userAgent,
      timestamp: new Date().toISOString(),
      performanceMetrics: getPerformanceMetrics(),
      recentNetworkCalls: getRecentNetworkCalls(),
      consoleLog: getRecentConsoleLogs(),
    };
  }, [getPerformanceMetrics, getRecentNetworkCalls, getRecentConsoleLogs]);

  const shouldSendError = useCallback((error) => {
    const errorKey = `${error.message}|${error.stack}`;
    const now = Date.now();
    const errorCache = JSON.parse(localStorage.getItem("errorCache") || "{}");
    const lastSent = errorCache[errorKey];

    if (lastSent && now - lastSent < 1200000) {
      // 15 minutes
      return false;
    }

    errorCache[errorKey] = now;
    localStorage.setItem("errorCache", JSON.stringify(errorCache));
    return true;
  }, []);

  const handleError = useCallback(
    (error, info = {}, userInfo = {}) => {
      if (shouldSendError(error)) {
        const errorInfo = {
          componentStack: info.componentStack,
          ...gatherAdditionalInfo(),
        };

        sendLog({
          type: "Error Boundary",
          message: error.message,
          stack: error.stack,
          userInfo,
          ...errorInfo,
        });
      }
    },
    [sendLog, gatherAdditionalInfo, shouldSendError],
  );

  return {
    handleError,
    getRecentConsoleLogs,
    getPerformanceMetrics,
    getRecentNetworkCalls,
  };
};
