import React from "react";
import ModalWrapper from "../../base/ModalWrapper/ModalWrapper";
import Text from "../../base/Text/Text";
import styles from "./GameLevelListModal.module.scss";
import { useApp } from "../../../context/AppProvider";
import { formatLargeNumber } from "../../../utils/strings";

export const GameLevelListModal = React.memo(
  ({ onClose, levels }) => {
    const groupLevels = (levels) => {
      return levels.reduce((acc, level) => {
        if (!acc[level.group]) {
          acc[level.group] = [];
        }
        acc[level.group].push(level);
        return acc;
      }, {});
    };

    const groupedLevels = groupLevels(levels);

    const getLevelRange = (levels) => {
      if (levels.length === 0) return "";
      const minLevel = levels[0].level;
      const maxLevel = levels[levels.length - 1].level;
      return `(${minLevel}-${maxLevel})`;
    };

    return (
      <ModalWrapper
        onClose={onClose}
        className={styles.skillInfoModal}
        backgroundClassName={styles.backgroundWrapper}
        header={<br />}
      >
        <div className={styles.content}>
          <Text
            variant="h1"
            weight="bold"
            color="gradient"
            className={styles.skillName}
            tag="div"
          >
            Levels Info
          </Text>
          {Object.entries(groupedLevels).map(([groupName, groupLevels]) => (
            <div key={groupName} className={styles.groupContainer}>
              <Text
                variant="textL"
                weight="bold"
                className={styles.groupName}
                tag="div"
              >
                {groupName}s {getLevelRange(groupLevels)}
              </Text>
              <div className={styles.levelsList}>
                {groupLevels.map((level) => (
                  <div key={level.level} className={styles.levelItem}>
                    <Text
                      variant="textM"
                      weight="semiBold"
                      className={styles.levelName}
                      tag="div"
                    >
                      {level.level} {level.name}
                    </Text>
                    {level?.reward > 0 && (
                      <div className={styles.rewardLable}>
                        <Text
                          variant="textXM"
                          weight="bold"
                          className={styles.rewardText}
                        >
                          Reward
                        </Text>
                        <img
                          src="/img/drumCoin.png"
                          alt="drum-coin"
                          width="18px"
                          className={styles.coinIcon}
                        />
                        <Text variant="textXM" weight="bold">
                          {formatLargeNumber(level.reward ?? 0)}
                        </Text>
                      </div>
                    )}
                    {/* <Text
                      variant="body"
                      weight="bold"
                      color="accent"
                      className={styles.levelReward}
                      tag="div"
                    >
                      Reward: {level.reward}
                    </Text> */}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </ModalWrapper>
    );
  },
  (prevProps, nextProps) => prevProps.levels === nextProps.levels,
);

export const GameLevelListContext = React.createContext(null);

export const GameLevelListProvider = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { userInfo = {} } = useApp();

  const handleModalState = (val) => {
    setIsOpen(val);
    console.log("handleModalState", val);
    if (val) {
      if (typeof window !== "undefined") {
        window.scrollTo(0, 0);
      }
    }
  };

  return (
    <GameLevelListContext.Provider
      value={{ isOpen, setIsOpen: handleModalState }}
    >
      {children}
      {isOpen && (
        <GameLevelListModal
          onClose={() => handleModalState(false)}
          levels={userInfo?.displayGameLevels ?? []}
        />
      )}
    </GameLevelListContext.Provider>
  );
};
