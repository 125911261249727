import { TelegramProvider } from "./context/TelegramProvider";
import { AppProvider } from "./context/AppProvider";
import Router from "./components/Router/Router";
import ErrorHandler from "./components/ErrorHandler";
import ErrorBoundary from "./components/ErrorBoundary";
import "./fonts/SF-Pro-Rounded-Medium.ttf";
import "./fonts/SF-Pro-Rounded-Bold.ttf";
import "./fonts/SF-Pro-Rounded-Semibold.ttf";

const App = () => {
  return (
    <TelegramProvider>
      <ErrorHandler>
        <ErrorBoundary>
          <AppProvider>
            <Router />
          </AppProvider>
        </ErrorBoundary>
      </ErrorHandler>
    </TelegramProvider>
  );
};

export default App;
