import React, { useEffect } from "react";
import clsx from "clsx";
import styles from "./ModalWrapper.module.scss";
import Icon from "../../Icon/Icon";
import {
  disableBodyScroll,
  enableBodyScroll,
} from "./../../../utils/bodyScrollBlock";

const ModalWrapper = ({
  children,
  onClose,
  header,
  className,
  headerClassName,
  backgroundClassName,
  closeButtonClassName,
  closeButtonPosition = "absolute",
}) => {
  useEffect(() => {
    const modalContent = document.querySelector(`.${styles.modalContent}`);
    if (modalContent) {
      disableBodyScroll(modalContent);
    }

    return () => {
      if (modalContent) {
        enableBodyScroll(modalContent);
      }
    };
  }, []);

  return (
    <div className={clsx(styles.modalBackground, backgroundClassName)}>
      <div className={clsx(styles.modalContent, className)}>
        <div className={clsx(styles.header, headerClassName)}>
          {closeButtonPosition === "inline" && (
            <button
              type="button"
              onClick={onClose}
              className={clsx(
                styles.closeButton,
                styles.inlineCloseButton,
                closeButtonClassName,
              )}
            >
              <Icon
                width={20}
                height={20}
                name="close"
                className={styles.closeIcon}
              />
            </button>
          )}
          {header}
        </div>
        <div className={styles.modalBody}>{children}</div>
        {closeButtonPosition === "absolute" && (
          <button
            type="button"
            onClick={onClose}
            className={clsx(
              styles.closeButton,
              styles.absoluteCloseButton,
              closeButtonClassName,
            )}
          >
            <Icon
              width={20}
              height={20}
              name="close"
              className={styles.closeIcon}
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default ModalWrapper;
