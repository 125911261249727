import clsx from "clsx";
import Icon from "../../../Icon/Icon";
import styles from "./DailyBonusList.module.scss";

import React from "react";

const DailyBonusList = React.memo(
  ({ completedDays = 0, claimableToday = false, claimedToday = false }) => {
    const totalDays = 7;

    return (
      <div className={styles.dailyBonusList}>
        {[...Array(totalDays)].map((_, index) => {
          const isLastDay = index === totalDays - 1;
          const isClaimed = index === completedDays - 1 && claimedToday;
          const isClaimable = index === completedDays && claimableToday;
          return (
            <div
              key={index}
              className={clsx(
                styles.checkbox,
                index < completedDays ? styles.completed : "",
                isLastDay ? styles.bonus : "",
                isClaimable
                  ? isLastDay
                    ? styles.extraGiftClaimable
                    : styles.claimable
                  : "",
                isClaimed ? styles.claimedToday : "",
              )}
            >
              {isClaimed && (
                <div
                  className={isLastDay ? styles.extraGift : styles.timeOverlay}
                />
              )}
              {isLastDay ? (
                <Icon name="extra-gift" />
              ) : index < completedDays ? (
                <Icon name="check-circle" />
              ) : null}
            </div>
          );
        })}
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.completedDays === nextProps.completedDays &&
      prevProps.claimableToday === nextProps.claimableToday &&
      prevProps.claimedToday === nextProps.claimedToday
    );
  },
);

export default DailyBonusList;
