export const declOfNum = (number, titles) => {
  const cases = [2, 0, 1, 1, 1, 2];

  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

export const formatNumberForUi = (number) => {
  number += "";
  const x = number.split(".");
  let x1 = x[0];
  const x2 = x.length > 1 ? "." + x[1]?.slice(0, 2) : "";
  const rgx = /(\d+)(\d{3})/;
  let iterationCount = 0;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + (iterationCount === 0 ? "’" : "‘") + "$2");
    iterationCount += 1;
  }
  return x1 + x2;
};

export function formatLargeNumber(input) {
  let num = 0;

  if (typeof input === "string") {
    num = parseFloat(input);
  } else if (typeof input === "number" && !isNaN(input)) {
    num = input;
  }

  if (num === 0 || isNaN(num)) {
    return "0";
  }

  const suffixes = ["", "K", "M", "B", "T"];
  let suffixIndex = 0;

  let absNum = Math.abs(num);
  while (absNum >= 1000 && suffixIndex < suffixes.length - 1) {
    absNum /= 1000;
    suffixIndex++;
  }

  absNum *= Math.sign(num);

  return absNum.toFixed(2).replace(/\.00$/, "") + suffixes[suffixIndex];
}
