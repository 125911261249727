import styles from "./Button.module.scss";
import clsx from "clsx";

const Button = ({
  type = "button",
  variant = "filled",
  fullWidth = false,
  size = "sizeL",
  className,
  onClick,
  disabled,
  ariaDisabled,
  children,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      aria-disabled={ariaDisabled}
      className={clsx(
        styles.btn,
        styles[variant],
        styles[size],
        fullWidth && styles.fullWidth,
        className && className,
      )}
    >
      {children}
    </button>
  );
};

export default Button;
