import React, { createContext, useState, useCallback, useEffect } from "react";
import { isEqual } from "lodash";
import styles from "./WatchAdModal.module.scss";
import ModalWrapper from "../../base/ModalWrapper/ModalWrapper";
import Text from "../../base/Text/Text";
import Button from "../../base/Button/Button";
import { useAdsgram } from "../../../hooks/useAdsGram";
import useApi from "../../../api/rest";
import { useApp } from "../../../context/AppProvider";

const testBlockId = "4895";
const prodBlockId = "4864";

export const WatchAdModal = ({ onClose, task }) => {
  const { sendLog, getUserInfo } = useApi();
  const { setUserInfo, userInfo } = useApp();
  const [isWatched, setIsWatched] = useState(false);
  const [isOnCooldown, setIsOnCooldown] = useState(false);
  const isProd = window.location.hostname === "drum.wigwam.app";
  const blockId = isProd ? prodBlockId : testBlockId;

  useEffect(() => {
    const checkCooldown = () => {
      const lastViewTime = localStorage.getItem("lastAdViewTime");
      if (lastViewTime) {
        const timeSinceLastView = Math.floor(
          (Date.now() - parseInt(lastViewTime)) / 1000,
        );
        const remainingTime = 60 - timeSinceLastView;
        setIsOnCooldown(remainingTime > 0);
      }
    };

    checkCooldown();

    const interval = setInterval(checkCooldown, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleReward = (result) => {
    setIsWatched(true);
    localStorage.setItem("lastAdViewTime", Date.now().toString());
  };

  const shouldSendError = useCallback((error) => {
    const errorKey = `${error.message}|${error.stack}`;
    const now = Date.now();
    const errorCache = JSON.parse(localStorage.getItem("errorCache") || "{}");
    const lastSent = errorCache[errorKey];

    if (lastSent && now - lastSent < 900000) {
      return false;
    }

    errorCache[errorKey] = now;
    localStorage.setItem("errorCache", JSON.stringify(errorCache));
    return true;
  }, []);

  const handleError = (error) => {
    onClose();

    if (shouldSendError(error)) {
      sendLog({
        type: "Error Handler",
        message: JSON.stringify(error, null, 2),
        stack: error.stack,
        url: window.location.href,
        timestamp: new Date().toISOString(),
      });
    }
  };

  const showAd = useAdsgram({
    blockId,
    onReward: handleReward,
    onError: handleError,
  });

  const handleWatchAd = () => {
    if (!isOnCooldown) {
      showAd();
    }
  };

  const updateUserInfo = useCallback(() => {
    getUserInfo().then((data) => {
      if (data.status === "error") {
      } else {
        if (!isEqual(userInfo, data)) {
          setUserInfo(data);
        }
      }

      onClose();
    });
  }, [getUserInfo, userInfo, setUserInfo, onClose]);

  return (
    <ModalWrapper onClose={onClose} header="" className={styles.watchAdModal}>
      <div className={styles.content}>
        <Text variant="h1" weight="bold" color="gradient">
          Watch an Ad today
        </Text>

        <Text variant="textM" weight="bold">
          Ads by external companies. Wigwam Drum Game is not responsible for
          their content.
        </Text>

        <div className={styles.imageWrapper}>
          <div className={styles.shineLow} />
          <img src="/img/farmOld.png" alt="" className={styles.image} />
        </div>

        {!isWatched ? (
          <>
            <div className={styles.balanceContainer}>
              <Text variant="textL" weight="bold">
                Reward: {task?.reward ?? 0} DRUMs
              </Text>
            </div>

            <Button
              variant="filledSec"
              size="sizeXL"
              fullWidth
              onClick={handleWatchAd}
              className={styles.watchBtn}
              ariaDisabled={isOnCooldown}
            >
              <Text variant="textL" weight="semiBold">
                {isOnCooldown
                  ? "To watch the next video you should wait 1 minute"
                  : "Watch Ad"}
              </Text>
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="filledAccent"
              size="sizeXL"
              fullWidth
              onClick={updateUserInfo}
              className={styles.watchFinishedBtn}
            >
              <Text variant="textL" weight="bold">
                Task finished! Your reward {task?.reward ?? 0} DRUMs
              </Text>
            </Button>
          </>
        )}
      </div>
    </ModalWrapper>
  );
};

export const WatchAdModalContext = createContext(null);

export const WatchAdModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);

  const handleModalState = (val, task) => {
    setIsOpen(val);
    if (val) {
      setCurrentTask(task);
      if (typeof window !== "undefined") {
        window.scrollTo(0, 0);
      }
    }
  };

  return (
    <WatchAdModalContext.Provider
      value={{
        isOpen,
        handleModalState,
        currentTask,
      }}
    >
      {children}
      {isOpen && (
        <WatchAdModal
          task={currentTask}
          onClose={() => handleModalState(false)}
        />
      )}
    </WatchAdModalContext.Provider>
  );
};
