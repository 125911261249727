import styles from "./GameLevelInfo.module.scss";

import React, { useContext } from "react";
import clsx from "clsx";

import Text from "../base/Text/Text";
import ProgressBar from "../base/ProgressBar/ProgressBar";
import { GameLevelListContext } from "../Modals/GameLevelListModal/GameLevelListModal";

const GameLevelInfo = ({
  gameLevel = {},
  className,

  groupLevelVariant = "textL",
  groupLevelColor = "white",
  groupLevelClassName,

  levelNameVariant = "textM",
  levelNameColor = "gradient",
  shineLevelName = true,

  withProgressBar = false,
  progressBarClassName,
}) => {
  const { setIsOpen } = useContext(GameLevelListContext);

  const handleClick = () => {
    setIsOpen(true);
  };

  return (
    <div className={clsx(styles.container, className)} onClick={handleClick}>
      <Text
        variant={groupLevelVariant}
        weight="bold"
        color={groupLevelColor}
        className={groupLevelClassName}
      >
        {`${gameLevel.group} ${gameLevel.level}`}
      </Text>
      <Text
        variant={levelNameVariant}
        weight="semiBold"
        color={levelNameColor}
        className={shineLevelName ? styles.gameLevelName : null}
      >
        {gameLevel.name}
      </Text>

      {withProgressBar && (
        <ProgressBar
          level={gameLevel?.level ?? 1}
          maxLevel={100}
          className={progressBarClassName}
        />
      )}
    </div>
  );
};

export default GameLevelInfo;
