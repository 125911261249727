import React, { useCallback, useMemo } from "react";
import styles from "./DrumList.module.scss";
import Text from "../../../base/Text/Text";
import Button from "../../../base/Button/Button";
import DrumIcon from "./DrumIcon";
import Icon from "../../../Icon/Icon";

const DrumItem = React.memo(
  ({
    drumId,
    drum,
    onUpgrade,
    canUpgradeDrum,
    getUpgradeButtonText,
    isActive,
    gameLevel,
    lastUpgradedDrumId,
  }) => {
    const handleClick = useCallback(() => {
      if (!isActive && drum?.isUpgraded) {
        return;
      }

      if (!isActive) {
        onUpgrade(drumId);
      }
    }, [isActive, onUpgrade, drumId, drum?.isUpgraded]);

    const totalDrumsPerTap = useMemo(() => {
      if (!drum) return "";

      const min = drum.tapRewardMin ?? 0;
      const max = drum.tapRewardMax ?? 0;

      if (min === max) {
        return min.toString();
      }

      return `${min}-${max}`;
    }, [drum]);

    const isDisabled =
      (!isActive &&
        !canUpgradeDrum &&
        (gameLevel ?? 0) < (drum?.levelRequired ?? 0)) ||
      !canUpgradeDrum ||
      (drum?.isUpgraded && !isActive) ||
      drumId !== (lastUpgradedDrumId ?? -1) + 1;

    const buttonClassName = isActive
      ? styles.selectedDrum
      : drum?.isUpgraded
      ? styles.selectDrumBtn
      : "";

    return (
      <div
        className={`${styles.drumItem} ${isActive ? styles.activeDrum : ""}`}
      >
        <div className={styles.iconWrapper}>
          <DrumIcon name={drum?.name ?? ""} className={styles.drumIcon} />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.drumInfo}>
            <Text
              variant="textL"
              weight="bold"
              align="left"
              className={styles.drumName}
            >
              {drum?.name ?? "Unknown Drum"}
            </Text>
            <Text
              variant="textXM"
              weight="semibold"
              align="left"
              className={styles.drumBonus}
            >
              +{totalDrumsPerTap} DRUMS Tap
            </Text>
          </div>

          <div className={styles.upgradeContainer}>
            <Button
              variant="filledAccent"
              onClick={handleClick}
              ariaDisabled={isDisabled}
              size="sizeS"
              className={buttonClassName}
            >
              {isActive && <Icon width="18" height="19" name="check" />}
              <Text variant="textXM" weight="semiBold">
                {getUpgradeButtonText(drum)}
              </Text>
            </Button>
            {!isActive && !drum?.isUpgraded && (
              <div className={styles.upgradeCost}>
                <img
                  src="/img/drumCoin.png"
                  alt="drum-coin"
                  width="18px"
                  className={styles.coinIcon}
                />
                <Text variant="textXM" weight="semiBold" color="gradient">
                  {drum?.cost ?? 0}
                </Text>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
);

const DrumList = React.memo(
  ({
    filteredDrums = [],
    onUpgrade,
    gameLevel = 0,
    selectedDrumId,
    lastUpgradedDrumId = -1,
  }) => {
    const getUpgradeButtonText = useCallback(
      (drum) => {
        if (!drum) return "Unknown";

        if (!drum.isActive && drum.isUpgraded) {
          return "Upgraded";
        }

        if (drum.isActive) {
          return "Selected";
        }

        if (gameLevel < (drum.levelRequired ?? 0)) {
          return `Need Level ${drum.levelRequired ?? 0}`;
        }

        return "Upgrade";
      },
      [gameLevel],
    );

    return (
      <div className={styles.drumsList}>
        {filteredDrums.map((drum) => (
          <DrumItem
            key={drum?.id ?? "unknown"}
            drumId={drum?.id}
            drum={drum}
            gameLevel={gameLevel}
            onUpgrade={onUpgrade}
            canUpgradeDrum={drum?.canUpgrade ?? false}
            getUpgradeButtonText={getUpgradeButtonText}
            isActive={drum?.id === selectedDrumId}
            lastUpgradedDrumId={lastUpgradedDrumId}
          />
        ))}
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.filteredDrums === nextProps.filteredDrums &&
      prevProps.gameLevel === nextProps.gameLevel &&
      prevProps.selectedDrumId === nextProps.selectedDrumId &&
      prevProps.lastUpgradedDrumId === nextProps.lastUpgradedDrumId
    );
  },
);

export default DrumList;
