import styles from "./Stats.module.scss";
import { useContext, useState, useEffect, memo, useMemo } from "react";

import Text from "../../base/Text/Text";
import Button from "../../base/Button/Button";

import { useApp } from "../../../context/AppProvider";
import useApi from "../../../api/rest";

import { FaqModalContext } from "../../Modals/FaqModal/FaqModal";
import GameLevelInfo from "../../GameLevelInfo/GameLevelInfo";
import { formatNumberForUi } from "../../../utils/strings";

const Stats = () => {
  const { userInfo = null } = useApp();
  const { getChildren } = useApi();
  const { setIsOpen: setFaqModalOpen } = useContext(FaqModalContext);

  const [childrenCount, setChildrenCount] = useState(0);

  useEffect(() => {
    const fetchChildrenCount = async () => {
      try {
        const response = await getChildren();
        const childrenCount = response?.data?.totalChildrenCount;
        if (childrenCount) {
          setChildrenCount(childrenCount);
        }
      } catch (error) {
        console.error("Error fetching children count:", error);
      }
    };

    fetchChildrenCount();
  }, [getChildren]);

  const totalSkillsUpdated = useMemo(() => {
    if (!userInfo?.skills) return 0;

    return Object.values(userInfo.skills).reduce((total, level) => {
      return total + (level - 1);
    }, 0);
  }, [userInfo?.skills]);

  const statsList = useMemo(
    () => [
      { label: "Total DRUMs spent", value: userInfo?.spentDrums ?? 0 },
      { label: "Current DRUMs balance", value: userInfo?.balance ?? 0 },
      {
        label: "Your Farm DRUMs Bonus",
        value: userInfo?.farmStats?.bonusFarmPerDay ?? 0,
      },

      { label: "Total Skills Updated", value: totalSkillsUpdated ?? 0 },
      { label: "Friends invited", value: childrenCount ?? 0 },
      // {
      //   label: "Your activity level for last period",
      //   value: `${userInfo?.periodActivityPercentage ?? 0}%`,
      // },
      // {
      //   label: "Your total activity level",
      //   value: `${userInfo?.totalActivityPercentage ?? 0}%`,
      // },
    ],
    [userInfo, childrenCount, totalSkillsUpdated],
  );

  return (
    <>
      <div id="no-user-select" className={styles.mainContainer}>
        <div className={styles.headerContainer}>
          <GameLevelInfo gameLevel={userInfo?.displayGameLevel} />
          <Button
            variant="filledAccent"
            size="sizeS"
            className={styles.faqButton}
            onClick={() => setFaqModalOpen(true)}
          >
            <Text variant="textM" weight="semiBold">
              FAQ
            </Text>
          </Button>
        </div>
        <div className={styles.contentContainer}>
          <Text variant="h1" weight="bold" color="gradient">
            Your game
            <br />
            activity stats
          </Text>
          <div className={styles.statList}>
            {statsList.map((stat, index) => (
              <div key={index} className={styles.statItem}>
                <Text variant="textL" color="gradient" align="left">
                  {stat.label}
                </Text>
                <Text variant="textL" weight="bold">
                  {formatNumberForUi(stat.value)}
                </Text>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Stats);
