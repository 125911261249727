import React from "react";

import Button from "../base/Button/Button";
import Icon from "../Icon/Icon";
import styles from "./NotificationsBell.module.scss";

import { useNotifications } from "../Screens/Tap/hooks/useNotifications";
import clsx from "clsx";

export const NotificationBell = () => {
  const { setIsOpen, hasNewNotifications } = useNotifications();

  return (
    <Button
      type="icon"
      variant="transparent"
      size="size0"
      className={styles.notificationsButton}
      onClick={() => setIsOpen(true)}
    >
      <Icon
        name="notifications"
        width={32}
        height={33}
        className={clsx(
          styles.notificationsIcon,
          hasNewNotifications ? styles.notificationsIconActive : "",
        )}
      />
      {hasNewNotifications && <div className={styles.notificationDot} />}
    </Button>
  );
};
