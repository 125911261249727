/* eslint-disable react/no-unknown-property */
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";

const initialContext = {};

export const TelegramContext = createContext(initialContext);

export const TelegramProvider = ({ children }) => {
  const [webApp, setWebApp] = useState(null);

  const initWebApp = useCallback(() => {
    const app = window.Telegram?.WebApp;
    if (app) {
      app.ready();
      setWebApp(app);
    }
  }, []);

  useEffect(() => {
    if (webApp === null) {
      initWebApp();
    }
  }, [webApp, initWebApp]);

  const value = useMemo(() => {
    if (webApp) {
      return {
        webApp,
        unsafeData: webApp.initDataUnsafe,
        user: webApp.initDataUnsafe.user,
      };
    }
    return {};
  }, [webApp]);

  return (
    <TelegramContext.Provider value={value}>
      {children}
    </TelegramContext.Provider>
  );
};

export const useTelegram = () => useContext(TelegramContext);
