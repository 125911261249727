import React, { useEffect, useRef } from "react";

const Advertisement = () => {
  const adContainerRef = useRef(null);

  useEffect(() => {
    const updateScale = () => {
      if (!adContainerRef.current) return;
      const scale = window.innerWidth < 468 ? window.innerWidth / 468 : 1;
      adContainerRef.current.style.transform = `scale(${scale})`;
    };

    const sdkScript = document.createElement("script");
    sdkScript.src = "https://sad.adsgram.ai/js/sad.min.js";
    sdkScript.async = true;

    const initScript = document.createElement("script");
    initScript.innerHTML = `
      !function(e,n,c,t,o,r,d) {
        !(function e(n,c,t,o,r,m,d,s,a) {
          s=c.getElementsByTagName(t)[0],
          ((a=c.createElement(t)).async=!0),
          (a.src="https://"+r[m]+"/js/"+o+".js?v="+d),
          (a.onerror=function(){
            a.remove(),(m+=1)>=r.length||e(n,c,t,o,r,m)
          }),
          s.parentNode.insertBefore(a,s)
        })(window,document,"script","671a593eee58ba6233cd338b",["cdn.bmcdn6.com"],0,new Date().getTime())
      }();
    `;

    document.head.appendChild(sdkScript);
    sdkScript.onload = () => {
      document.head.appendChild(initScript);
    };

    window.addEventListener("resize", updateScale);
    updateScale();

    return () => {
      sdkScript.remove();
      initScript.remove();
      window.removeEventListener("resize", updateScale);
      const adScripts = document.querySelectorAll(
        'script[src*="cdn.bmcdn6.com"]',
      );
      adScripts.forEach((script) => script.remove());
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        margin: "0px",
        marginTop: "-16px",
        justifyContent: "center",
        transformOrigin: "top center",
      }}
    >
      <ins
        ref={adContainerRef}
        className="671a593eee58ba6233cd338b"
        style={{
          display: "inline-block",
          width: "468px",
          height: "60px",
          backgroundColor: "transparent",
        }}
      />
    </div>
  );
};

export default Advertisement;
